import firebase from './firebaseApi';
import invariant from 'invariant';
import {invariantLoggedIn} from './assertions';

export interface Monster {
  id: string;
  name: string;
  imgUrl?: string;
  infoUrl?: string;
  maxHealth: number;
}

export async function fetchMonsterList(): Promise<Monster[]> {
  const db = firebase.firestore();

  invariantLoggedIn();

  const monsters = await db.collection('monsters').get();
  return monsters.docs.map(document => {
    invariant(document.exists, 'Monster list item did not exist?!');
    return {
      ...document.data(),
      id: document.id,
    };
  }) as any as Monster[];
}

export async function getMonster(id: string): Promise<Monster> {
  const db = firebase.firestore();
  
  invariantLoggedIn();

  const monster = await db.collection('monsters').doc(id).get();
  invariant(monster.exists, 'Monster with this ID does not exist');
  return monster.data() as Monster;
}

export async function addMonster(name: string, imgUrl: string, infoUrl: string, maxHp: number) {
  const db = firebase.firestore();
  invariantLoggedIn();
  const uid = firebase.auth().currentUser?.uid;
  const result = await db.collection('monsters').add({
    name: name,
    imgUrl: imgUrl,
    infoUrl: infoUrl,
    maxHealth: maxHp,
    creatorUid: uid,
  });
  invariant(result.id !== null, 'Monster was not created successfully');
}

export async function deleteMonster(id: string, name: string) {
  const db = firebase.firestore();
  invariantLoggedIn();
  // eslint-disable-next-line no-restricted-globals
  if (confirm(`Are you sure you want to delete ${name}`)) {
    await db.collection('monsters').doc(id).delete();
  }
}
