import React, { useEffect, useState } from 'react';
import './SessionOverview.scss';
import { Link, Redirect, useParams } from 'react-router-dom';
import { endSession, getSessionMonsters, MonsterInstanceWithType } from '../Apis/sessionManagementApi';
import {useGoogleLogin} from '../Auth/LoginHook';


export const SessionOverview = () => {
  const { session_id } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [sessionMonsterList, setSessionMonsters] = useState<MonsterInstanceWithType[] | undefined>([]);

  // isLoggedIn is false for a second after the page loads
  const { loading } = useGoogleLogin();

  useEffect(() => {
    if (loading) {
      return;
    }
    getSessionMonsters(session_id).then(monsters => {
      setSessionMonsters(monsters);
    }, (error) => {
      console.error('Session is invalid', error);
      setRedirect(true);
    });
  }, [session_id, loading]);


  if (redirect) {
    return <Redirect to={'/'}/>;
  }
  return (

    <div className="session_overview container">

      {sessionMonsterList?.length === 0 && (

        <div className="empty">
          <h1>No monsters</h1>
          <div className="text warning">
            You currently have no monsters in this session
          </div>

        </div>
      )}
      {sessionMonsterList?.length !== 0 && (<>

        <h1>Alive monsters</h1>
        <div className="monsters">
          {sessionMonsterList?.map((monster) => (
            <Link to={`/session/${session_id}/deal_damage/${monster.id}`} key={monster.id}>

              <button
                className="secondary monster"
                key={monster.id}
              >
                {monster.monsterType.name} {monster.currentHealth}

              </button>
            </Link>

          ))}</div>
      </>)


      }
      <div className="buttons">
        <Link to={`/session/${session_id}/add_monster`}>

          <button className="main">
            Add new monster
          </button>
        </Link>
        <Link to={`/session/${session_id}/monster_list`}>
          <button className="main">
            Choose existing
          </button>
        </Link>
      </div>
      <button
        onClick={async () => {
          await endSession(session_id);
          setRedirect(true);
        }}
        className="danger">
        End session
      </button>
    </div>

  );
};
