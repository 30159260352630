import React, { useState } from 'react';
import './AddMonster.scss';
import { Link, Redirect, useParams } from 'react-router-dom';
import { addMonster } from '../Apis/monsterApi';


export const AddMonster = () => {
  const { session_id } = useParams();

  const [name, setName] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [infoUrl, setInfoUrl] = useState('');
  const [maxHp, setMaxHp] = useState(0);
  const [goToList, setGoToList] = useState(false);

  if (goToList) {
    return (
      <Redirect to={`/session/${session_id}/monster_list`}/>
    );
  }
  return (
    <div className="add_monster container">
      <h1>Add new monster</h1>
      <div className="input_with_label">
        <label
          htmlFor="monster_name">
          Name
        </label>
        <input id="monster_name"
               type="text"
               required={true}
               value={name}
               onChange={(event) => {
                 setName(event.target.value);
               }}
        />
      </div>
      <div className="input_with_label">
        <label
          htmlFor="img_url">
          IMG url
        </label>
        <input id="img_url"
               type="url"
               required={true}
               value={imgUrl}
               onChange={(event) => {
                 setImgUrl(event.target.value);
               }}
        />
      </div>

      <div className="input_with_label">
        <label
          htmlFor="info_url">
          info
        </label>
        <input id="info_url"
               type="url"
               required={true}
               value={infoUrl}
               onChange={(event) => {
                 setInfoUrl(event.target.value);
               }}
        />


      </div>

      <div className="input_with_label">
        <label
          htmlFor="max_hp">
          MAX HP
        </label>
        <input
          id="max_hp"
          required={true}
          type="number"
          value={maxHp}
          onChange={(event) => {
            setMaxHp(parseInt(event.target.value, 10));
          }}
        />


      </div>

      <button
        type={'submit'}
        onClick={() => {
          addMonster(name, imgUrl, infoUrl, maxHp);
          setGoToList(true);

        }}
        className="main">
        add
      </button>

      <Link
        className="back_button"
        to={`/session/${session_id}/monster_list`}>
        Back
      </Link>
    </div>
  );
};
