import React, { useEffect, useState } from 'react';
import './MonsterView.scss';
import { Link, useParams } from 'react-router-dom';
import { deleteMonster, getMonster, Monster } from '../Apis/monsterApi';
import { Redirect } from 'react-router-dom';
import {useGoogleLogin} from '../Auth/LoginHook';


export const MonsterView = () => {
  const { session_id, monster_id } = useParams();

  const [monsterSolo, setMonsterSolo] = useState<Monster>();
  const [redirect, setReDirect] = useState(false);
  const { loading } = useGoogleLogin();


  useEffect(() => {
    if (loading) {
      return;
    }
    getMonster(monster_id).then(monster => {
      setMonsterSolo(monster);
    });
  }, [monster_id, loading]);

  if (redirect) {
    return <Redirect to={'/monster_list'}/>;
  }
  return (<div className="monster container">
    {!monsterSolo && (
      <>
        <h1>Whoopsie!</h1>
        <Link to={'/monster_list'}>
          Back
        </Link>
      </>
    )}
    <h1>{monsterSolo?.name}</h1>
    <img
      src={monsterSolo?.imgUrl ? monsterSolo?.imgUrl : 'https://filmdaily.co/wp-content/uploads/2020/04/cute-cat-videos-lede.jpg'}
      alt="monster"
      className="monster_img"
    />

    <div className="max_health">
      MAX HP
      <div className="health_number"> {monsterSolo?.maxHealth}</div>
    </div>

    {monsterSolo?.infoUrl && (
      <div className="info">
        <a href={monsterSolo?.infoUrl}>
          Click for more info
        </a>
      </div>

    )}
    <Link
      className="back_button"
      to={`/session/${session_id}/monster_list`}>
      Back
    </Link>
  </div>);
};
