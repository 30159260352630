import React, { useState, } from 'react';
import './IndexPage.scss';
import dragon_image from '../assets/dragons.png';
import { Link, Redirect } from 'react-router-dom';
import { startNewSession } from '../Apis/sessionManagementApi';
import {useGoogleLogin} from '../Auth/LoginHook';


export const IndexPage = () => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [goToSessionId, setGoSessionId] = useState<string | undefined>(undefined);

  const { user, error, startLogin } = useGoogleLogin();

  if (redirect) {
    return <Redirect to={`/session/${redirect}`}/>;
  }


  return (<div className="index_page_container container">
    <h1>Welcome to BattleApp</h1>
    <img
      src={dragon_image}
      className="dragon_image"
      alt="Dragons"/>

    { error && (
      <>
        <p>There was a problem logging you in.</p>
      </>
    )}

    { user ? (
      <>
        <button
          onClick={async () => {
            setRedirect(await startNewSession());
          }}
          className="main">

          Start game
        </button>

        <div className="input_with_label">
          <label
            htmlFor="session_id">
            Session id
          </label>
          <input id="session_id"
                type="text"
                required={true}
                value={goToSessionId}
                onChange={(event) => {
                  setGoSessionId(event.target.value);
                }}
          />
        </div>

        <Link to={`/session/${goToSessionId}`}>
          <button className="go_to_session secondary">

            Load session

          </button>
        </Link>
      </>
    ) : (
      <button onClick={startLogin}>Login with Google</button>
    )}
  </div>);
};
