import {useCallback, useState, useEffect} from "react";
import firebase from '../Apis/firebaseApi';

export interface IUser {
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  photoURL: string | null;
  isAnonymous: boolean;
  uid: string;
}


/**
 * A custom hook that logs you in to Google and maintains login state.
 */
export function useGoogleLogin() {
  const [credential, setCredential] = useState<any>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const isLoggedIn = !!user;

  useEffect(() => {
    // onAuthStateChanged returns an unsubscribe function
    return firebase.auth().onAuthStateChanged((user) => {
      setUser(user || null);
      setLoading(false);
    });
  }, []);

  const startLogin = useCallback(async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const { credential } = await firebase.auth().signInWithPopup(provider);
      setCredential(credential);
    } catch (err) {
      console.error('User has failed to sign in', err);
      setCredential(null);
      setUser(null);
      setError(err);
    }
  }, []);

  return {
    startLogin,
    loading,
    credential,
    user,
    isLoggedIn,
    error,
  };
}
