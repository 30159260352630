import React, { useEffect, useState } from 'react';
import './DealDamage.scss';
import { Link, useParams } from 'react-router-dom';
import {
  changeCurrentHealth,
  deleteMonsterInstance,
  getMonsterInstance,
  MonsterInstance,
} from '../Apis/sessionManagementApi';
import { getMonster, Monster } from '../Apis/monsterApi';
import {useGoogleLogin} from '../Auth/LoginHook';


export const DealDamage = () => {
  const { session_id, monster_instance_id } = useParams();
  const [monsterInstance, setMonsterInstance] = useState<MonsterInstance>();
  const [monsterType, setMonsterType] = useState<Monster>();
  const [dealtDamage, setDealtDamage] = useState(0);
  const { loading } = useGoogleLogin();

  useEffect(() => {
    if (loading) {
      return;
    }
    getMonsterInstance(monster_instance_id).then(monster => {
      setMonsterInstance(monster);
    });
  }, [monster_instance_id, loading]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!monsterInstance) {
      return;
    }
    getMonster(monsterInstance?.monsterId).then(monster => {
      setMonsterType(monster);
    });
  }, [monsterInstance, loading]);

  return (
    <div className="deal_damage container">
      <h1>{monsterType?.name}</h1>
      <Link
        className="back_button"
        to={`/session/${session_id}`}>
        Back
      </Link>
      <img
        src={monsterType?.imgUrl ? monsterType?.imgUrl : 'https://filmdaily.co/wp-content/uploads/2020/04/cute-cat-videos-lede.jpg'}
        alt="monster"
        className="monster_img"
      />
      <div className="healths">
        <div className="max">
          Max
          <div className="health_number">{monsterType?.maxHealth}</div>

        </div>
        <div className="current">Current
          <div className="health_number">{monsterInstance?.currentHealth}
          </div>

        </div>
      </div>
      <div className="deal_damage_input_button">
        <div className="input_with_label">
          <label
            htmlFor="max_hp">
            damage
          </label>
          <input
            id="max_hp"
            required={true}
            type="number"
            value={dealtDamage}
            onChange={(event) => {
              setDealtDamage(parseInt(event.target.value, 10));
            }}
          />


        </div>
        <button
          onClick={() => {
            changeCurrentHealth(monsterInstance?.id, dealtDamage);
          }}
          className="deal_damage danger">
          hurt
        </button>
      </div>
      <button
        onClick={() => {
          deleteMonsterInstance(monsterInstance?.id);
        }}
        className="danger">
        DEAD
      </button>
    </div>
  );
};
