import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyB6iyTn8D6aTMS0Y4UOJn0WXSL1_RcsKew",
  authDomain: "dndbattleapp.firebaseapp.com",
  databaseURL: "https://dndbattleapp.firebaseio.com",
  projectId: "dndbattleapp",
  storageBucket: "dndbattleapp.appspot.com",
  messagingSenderId: "888202556500",
  appId: "1:888202556500:web:c4927c0eeee3268d2769c1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Detect the user's language from the device
firebase.auth().useDeviceLanguage();

// Keep the user logged in until they log out
// (as opposed to destroying their login state after the tab is closed)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default firebase;
