import invariant from 'invariant';
import firebase from './firebaseApi';
import { getMonster, Monster } from './monsterApi';
import {invariantLoggedIn} from './assertions';


export interface Session {
  id: string;
  master?: string;
  players?: string[];
  sessionMonsters: string[];
}

export interface MonsterInstance {
  id: string;
  currentHealth: number;
  monsterId: string;
}

export interface MonsterInstanceWithType extends MonsterInstance {
  monsterType: Monster;
}

export async function startNewSession() {
  const db = firebase.firestore();
  invariantLoggedIn();

  const session = await db.collection('gameSession').add({
    createdAt: new Date().toISOString(),
    sessionMonsters: [],
    creatorUid: firebase.auth().currentUser?.uid,
  });
  return session.id;
}

export async function endSession(id: string) {
  invariantLoggedIn();
  invariant(typeof id === 'string', 'Tried to end session without passing ID');

  const db = firebase.firestore();
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to end this session?')) {

    // await db.collection("monsterInstance").doc(*).delete();
    const relatedMonsterInstances = (await db.collection('gameSession').doc(id).get()).data()?.sessionMonsters;

    if (relatedMonsterInstances && relatedMonsterInstances.length > 0) {
      for (const id of relatedMonsterInstances) {
        await db.collection('monsterInstance').doc(id).delete();
      }
    }


    await db.collection('gameSession').doc(id).delete();
  }
}

export async function getSessionMonsters(id: string): Promise<MonsterInstanceWithType[] | undefined> {
  invariant(typeof id === 'string', 'Monster instance ID was not provided');
  invariantLoggedIn();
  const db = firebase.firestore();

  let session: firebase.firestore.DocumentSnapshot | null = null;
  try {
    session = await db.collection('gameSession').doc(id).get();
  } catch (err) {
    console.error('Session code is invalid');
    throw new Error('Session is invalid');
  }

  if (session?.exists) {
    // monsters is an array of monster instance IDs
    const sessionData = session.data() as Session;


    // SESSION has_many MONSTER INSTANCES
    // MONSTER INSTANCE has one MONSTER TYPE

    // query all monster instances given the list of IDs
    const monsterInstances: MonsterInstance[] = (await Promise.all(sessionData?.sessionMonsters.map(async (monsterInstanceId) => {
      const instanceData = (await db
          .collection('monsterInstance')
          .doc(monsterInstanceId)
          .get()
      ).data();
      

      if (!instanceData) {
        return undefined;
      }

      return {
        id: monsterInstanceId,
        monsterId: instanceData?.monsterId,
        currentHealth: instanceData?.currentHealth,
      };
    }))).filter(x => x) as MonsterInstance[];

    // query all monster types given list of monster instances
    const monsterInstancesWithTypes: MonsterInstanceWithType[] = await Promise.all(
      monsterInstances.map(async (monsterInstance): Promise<MonsterInstanceWithType> => { 
        return {
          id: monsterInstance.id,
          currentHealth: monsterInstance.currentHealth,
          monsterId: monsterInstance.monsterId,
          monsterType: await getMonster(monsterInstance.monsterId),
        };
      }),
    );

    return monsterInstancesWithTypes;

  }
}

export async function getMonsterInstance(id: string): Promise<MonsterInstance> {
  invariant(typeof id === 'string', 'Monster instance ID was not provided');
  invariantLoggedIn();
  const db = firebase.firestore();

  const monster = await db.collection('monsterInstance').doc(id).get();
  if (monster.exists) {

    return {
      ...monster.data(),
      id: monster.id,
    } as MonsterInstance;
  }
  throw new Error('No monster for you');
}


export async function addMonsterToSession(id: string, monsterId: string) {
  invariant(typeof id === 'string', 'Tried to add monster to session without providing session ID');
  invariant(typeof monsterId === 'string', 'Tried to add monster to session without monster ID');
  invariantLoggedIn();
  const db = firebase.firestore();

  const originalMonster = await db.collection('monsters').doc(monsterId).get();

  const sessionMonster = await db.collection('monsterInstance').add({
    monsterId: monsterId,
    currentHealth: originalMonster.data()?.maxHealth,
    creatorUid: firebase.auth().currentUser?.uid,
  });
  await db.collection('gameSession').doc(id).update({
    sessionMonsters: firebase.firestore.FieldValue.arrayUnion(sessionMonster.id),
  });
}

export async function deleteMonsterInstance(id: string | undefined) {
  invariantLoggedIn();
  invariant(typeof id === 'string', 'Tried to delete monster instance without instance ID!');
  const db = firebase.firestore();

  // eslint-disable-next-line no-restricted-globals
  if (confirm(`Are you sure this monster is dead`)) {
    await db.collection('monsterInstance').doc(id).delete();
  }
}

export async function changeCurrentHealth(id: string | undefined, damage: number) {
  invariantLoggedIn();
  invariant(typeof id === 'string', 'Tried to delete monster instance without instance ID!');
  
  const db = firebase.firestore();
  // eslint-disable-next-line no-restricted-globals
  db.collection('monsterInstance').doc(id).update({
    currentHealth: firebase.firestore.FieldValue.increment(-damage),
  });
}


