import React from 'react';

import { IndexPage } from './IndexPage/IndexPage';
import { Route, Switch } from 'react-router-dom';
import { AddMonster } from './AddMonster/AddMonster';
import { DealDamage } from './DealDamage/DealDamage';
import { MonsterList } from './MonsterList/MonsterList';
import { SessionOverview } from './SessionOverview/SessionOverview';
import { MonsterView } from './Monster/MonsterView';

function App() {
  return (

    <div className="content">

      <Switch>
        <Route
          path="/"
          exact={true}
          component={IndexPage}
        />


        <Route
          path="/session/:session_id/monster_list"
          exact={true}
          component={MonsterList}
        />

        <Route
          path="/session/:session_id/monster_view/:monster_id"
          exact={true}
          component={MonsterView}
        />

        <Route
          path="/session/:session_id/add_monster"
          exact={true}
          component={AddMonster}
        />

        <Route
          path="/session/:session_id/deal_damage/:monster_instance_id"
          exact={true}
          component={DealDamage}
        />
        <Route
          path="/session/:session_id"
          exact={true}
          component={SessionOverview}
        />
      </Switch>
    </div>
  );
}

export default App;
