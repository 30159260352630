import React, { useEffect, useState } from 'react';
import './MonsterList.scss';
import { Link, useParams } from 'react-router-dom';
import { fetchMonsterList, Monster } from '../Apis/monsterApi';
import { addMonsterToSession } from '../Apis/sessionManagementApi';
import dragon_image from '../assets/dragons.png';

export const MonsterList = () => {
  const { session_id } = useParams();
  const [monsterList, setMonsters] = useState<Monster[]>([]);


  useEffect(() => {
    fetchMonsterList().then(monsters => {
      setMonsters(monsters);
    });
  }, []);

  return (

    <div className="monster_list">
      <Link
        className="to_home"
        to={`/session/${session_id}`}>
        <img src={dragon_image} alt=""/>
      </Link>
      {monsterList.length === 0 && (

        <div className="empty">
          <h1>You currently have no monsters</h1>
          <Link to={'/add_monster'}>
            <button className="main">

              Add new
            </button>
          </Link>

        </div>
      )}
      {monsterList.length !== 0 && (
        <>
          <h1>Monster list</h1>
          <div className="monsters">
            {monsterList.map((monster) => (

              <div
                key={monster.id}
                className="monster">
                <Link to={`/session/${session_id}/monster_view/${monster.id}`}>
                  {monster.name}
                </Link>
                <button
                  onClick={() => {
                    addMonsterToSession(session_id, monster.id);
                  }}
                  className="secondary">
                  Add to session
                </button>
              </div>
            ))}</div>

          <Link to={`/session/${session_id}/add_monster`}>
            <button
              className="secondary add">
              Add new
            </button>
          </Link>

        </>
      )}

    </div>

  );
};
